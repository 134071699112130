import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
/* import { GET_VIEWdriverlist } from '../../actions'; */
import { apiUrl,mediaUrl} from'../Config/Config';
import axios from 'axios';
import moment from 'moment';

import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const driverid = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;

    this.state = {      
      driverid: driverid,
      driverlistview:'',
     
     
     
    };

    axios.get(apiUrl+"driver/listdetail?id="+driverid).then(res => {
			if(res.data.status == 'success'){
				const formdata = res.data.driverlist;
			if(formdata.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

			}
			this.setState({driverlistview:res.data.driverlist});
		
						
			}
			
		}); 
  }

  planlist() {
    console.log(this.state.membership_details,'famlist');
    var planlist = this.state.membership_details;
    if (planlist != "undefined" && planlist != null) {
      //if ( == "success"){
        if (Object.keys(planlist).length > 0) {
          const planlistDetails = planlist.map(
            (planlist, Index) => {
              let sno = Index+1;
              return (

              
                <tr key={planlist.id}>
				         <td>{sno}</td>
                   <td>
                  
                  {moment(planlist.created_date).format('DD MMM YYYY')}
                  </td>
                  <td>
                  {planlist.title}
                  </td>
                  <td>
                  {planlist.paid_amount}/{planlist.period=='month'?'m':'y'}
                  </td>
                
                  <td>
                  {planlist.current_status=='A'?'Active':'Inactive'}
                  </td>
                 
                 
                 
                </tr>
              );
            }
          );
          return planlistDetails;
        }else {
          return (
            <tr>
              <td colspan="5" className="v-align-nr">
                No Result
              </td>
            </tr>
          );
        }
      
    }else {
      return (
        <tr>
          <td colspan="4" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }
   componentDidMount() {
     document.title = PageTitle('User View');
   }

   componentDidUpdate(prevProps,prevState){

   }
   componentWillReceiveProps(Props){
   
    
   }
   
  render() {
    
  	

    var mobileno = ((this.state.driverlistview.mobileno === null)||((this.state.driverlistview.mobileno === ''))) ?  this.state.driverlistview.mobileno = 'N/A' : this.state.driverlistview.mobileno;

    
    if(this.state.driverlistview.status == 'A'){
      var status = 'Active';
    }else{
        var status = 'Inactive';
    }
    
  
    
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="drivers" />  
      
      <div className="content"> 
        <div className="content-wrapper">
         <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
                <div className="title">
                  <h3>Driver Details</h3>
                </div>
                <div className="form-row">
                    <div className="details_left">
                    <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Driver Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.driverlistview.firstname}</p>
                          </div>
                        </div>
                        
                        {/* <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Profile Image<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                         
		                          <img className="img_class" src={loaderimg} alt="" />
                          </div>
                        </div> */}
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>First Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.driverlistview.firstname!=null?this.state.driverlistview.firstname:'N/A'}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Last Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.driverlistview.lastname!=null?this.state.driverlistview.lastname:'N/A'}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Email<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.driverlistview.email}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Contact<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.driverlistview.mobileno}</p>
                          </div>
                          
                        </div>
                       
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Domain<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                          <p>{this.state.driverlistview.domain_name}</p>
                          </div>
                        </div>
                        
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Status<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                          <p>{status}</p>
                          </div>
                        </div>


                    </div>
                </div>
                
                  
                     
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}
export default withRouter(View);
