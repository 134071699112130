import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Table
} from "react-bootstrap"; 
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import {apiUrl, mediaUrl} from'../Config/Config';
import axios from 'axios';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import moment from 'moment';

class List extends Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      faqjsonlist:'',
      multilist:'',
      domainlist:[],
      selectedCateOption:'',
      selectedCatevalue:'',
      aifeedIDs:''
    };
    


    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    

    componentDidMount() {
      var qs = require('qs');
      var admin_id = localStorage.getItem("admin_id");
      var loggedas = localStorage.getItem("loggedas");
      var postobject = {
      activePage: 1,
      search_all: '',
      admin_id: admin_id,
      loggedas: loggedas
      };  

      axios.post(apiUrl+"faq/aifeedlist",qs.stringify(postobject)).then(res => {
        if(res.data.status === 'success'){
         
            this.setState({  faqjsonlist: res.data.faqjsonlist, totalRecord: res.data.records_count, multilist: res.data.multilist}, function() {
            
              this.faqjsonlist();
              this.multilist();
            });
  
        }
      }); 
   }

   handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
     this.setState({activePage: pageNumber});

     var qs = require('qs');

     var admin_id = localStorage.getItem("admin_id");
     var loggedas = localStorage.getItem("loggedas");
     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
       admin_id: admin_id,
       loggedas:loggedas
     };
    //  this.props.getfaqjsonlist(qs.stringify(postobject))
    axios.post(apiUrl+"faq/aifeedlist",qs.stringify(postobject)).then(res => {
      
      if(res.data.status === 'success'){
          this.setState({  faqjsonlist: res.data.faqjsonlist, totalRecord: res.data.records_count}, function() {
            this.faqjsonlist();
          });

      }
    }); 

   }

   handleInputChange(event) {
   const {name, value} = event.target;      
   this.setState({
     [name]: value
   });
   
 }

 handleChange = (selOption) => {       	
    this.setState({selectedCateOption:selOption})
    this.setState({  selectedCatevalue : selOption.value});
 };

 handleFormSubmit = () => {
        
  this.setState({Loading:true});
  var qs = require('qs');
  const formPayload = this.state;
   var admin_id = localStorage.getItem("admin_id");
   var loggedas = localStorage.getItem("loggedas");
  var postObject = {             
    activePage   : 1,
    search_all : formPayload.search_all,
    category : formPayload.selectedCatevalue,
    admin_id: admin_id,
    loggedas: loggedas         
  };
  axios.post(apiUrl+"faq/aifeedlist",qs.stringify(postObject)).then(res => {
    if(res.data.status === 'success'){

        this.setState({  faqjsonlist: res.data.faqjsonlist, totalRecord: res.data.records_count, multilist: res.data.multilist}, function() {
          this.faqjsonlist();
        });

    }
  }); 

} 

teachai = (id) => {
  console.log(id, 'polkmn');

  var qs = require('qs');

  var postObject = {   
      id: id   
  };
  axios.post(apiUrl+"faq/uploadAIFilesToTeach",qs.stringify(postObject)).then(res => {
    console.log(res, 'ai_res');
    if(res.data.status !== ''){
        $('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
              
        if(res.data.status == 'success'){
          window.location.reload();
        }

        setTimeout(
        function() {
          $('.success_message').html('');
        }
        .bind(this),
        3000
        );
    }
  }); 
}

deleteai = (id) => {

  var conf = window.confirm('Are you sure! want to delete?');
  if(conf == true){
    var qs = require('qs');

    var postObject = {   
        id: id   
    };
    axios.post(apiUrl+"faq/deleteAIFiles",qs.stringify(postObject)).then(res => {
      
      if(res.data.status !== ''){
          $('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
              
          if(res.data.status == 'success'){
            window.location.reload();
          }

          setTimeout(
          function() {
            $('.success_message').html('');
          }
          .bind(this),
          3000
          );
      }
    }); 
  }
}

deleteai2 = (id) => {

  var conf = window.confirm('Are you sure! want to delete?');
  if(conf == true){
    var qs = require('qs');

    var postObject = {   
        id: id   
    };
    axios.post(apiUrl+"faq/deleteAIMulti",qs.stringify(postObject)).then(res => {
      
      if(res.data.status !== ''){
          $('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
              
          if(res.data.status == 'success'){
            window.location.reload();
          }

          setTimeout(
          function() {
            $('.success_message').html('');
          }
          .bind(this),
          3000
          );
      }
    }); 
  }
}

checkRow = (id) => {
  console.log('id', id);
  var aifeedidArr = [...this.state.aifeedIDs];
  const index = aifeedidArr.findIndex(aifeed_list => aifeed_list === id);
  if(index > -1) {
    aifeedidArr = [...aifeedidArr.slice(0, index), ...aifeedidArr.slice(index + 1)]
  } else {
    aifeedidArr.push(id);   
  }
  // console.log('aifeedidArr', aifeedidArr);
  this.setState({aifeedIDs: aifeedidArr});
}

teachai2 = () => {
  // console.log('aifeedidArr', this.state.aifeedIDs);
  var aifeedIDs = this.state.aifeedIDs;
  if(aifeedIDs.length > 0){
    var qs = require('qs');

    var postObject = {   
        ids: aifeedIDs   
    };
    axios.post(apiUrl+"faq/uploadMultipleFilesToTeachAI",qs.stringify(postObject)).then(res => {
      // console.log(res, 'ai_res');
      if(res.data.status !== ''){
          $('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
                
          if(res.data.status == 'success'){
            window.location.reload();
          }

          setTimeout(
          function() {
            $('.success_message').html('');
          }
          .bind(this),
          3000
          );
      }
    }); 
  }else{
    alert('Please select atleast one');
  }
}

faqjsonlist() {
  let catImg = '';
  let preview = '';
    var faqjsonlist = this.state.faqjsonlist;
    if (faqjsonlist != "undefined" && faqjsonlist != null) {
      console.log(faqjsonlist);
    
      if (faqjsonlist.length > 0) {

        if (Object.keys(faqjsonlist).length > 0) {
          const helperlistDetails = faqjsonlist.map(
            (faqjsonlist, Index) => {
              let sno = Index+1;
              

               if(faqjsonlist.status == 'A'){
                var status = 'Active';
              }else{
                  status = 'Inactive';
              }

              var answer = faqjsonlist.faq_answer;
              var json_gen_date = faqjsonlist.json_gen_date;
              var js_date = moment(json_gen_date).format("MM-DD-YYYY hh:mm A");

              var disabled, dlt_btn, btn_text = '';
              var json_response = faqjsonlist.json_response;

              var sts_txt = "-"
              if(json_response == ""){
                btn_text = <Link onClick={this.teachai.bind(this, faqjsonlist.id)} className="" title="Teach AI">  <button>Teach AI</button> </Link>; ;
              }

              if(json_response != ""){
                disabled = 'disabled';
                sts_txt = 'Teaching';
                dlt_btn = <Link onClick={this.deleteai.bind(this, faqjsonlist.id)} className="" title="Delete">  <i className="fa fa-trash" aria-hidden="true"></i> </Link>;
              }

              if(faqjsonlist.feed_status == 1){
                sts_txt = 'Feeded';
              }
              
              var mod_disp = "-";
              if(faqjsonlist.json_response != "" && faqjsonlist.model_id == ""){
                mod_disp = "In Progress";
              }else if(faqjsonlist.json_response != "" && faqjsonlist.model_id != ""){
                mod_disp = faqjsonlist.model_id;
              }
             
              return (
                <tr key={faqjsonlist.id}>
                <td><input type='checkbox' onClick={this.checkRow.bind(this,faqjsonlist.id)} /></td>
      					{/* <td width="10px">{sno} </td> */}
      					<td>
                  <span class="svgspan">
                    {faqjsonlist.csv_filename}
                    
                    <a download target="_blank" href={mediaUrl +'faq_json/'+faqjsonlist.json_filename}>
                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                      width="30px" height="30px" viewBox="0 0 512.000000 512.000000"
                      preserveAspectRatio="xMidYMid meet">

                      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                      fill="#000000" stroke="none">
                      <path d="M1174 4820 c-57 -23 -54 25 -54 -967 l0 -913 100 0 100 0 0 845 0
                      845 1223 0 1222 0 307 -318 307 -317 1 -1752 0 -1753 -1530 0 -1530 0 0 365 0
                      365 -100 0 -100 0 0 -434 0 -435 24 -28 24 -28 1664 -3 c1239 -2 1670 1 1691
                      9 61 25 57 -104 57 1919 l0 1851 -159 167 c-88 92 -253 263 -368 380 l-208
                      213 -1325 -1 c-729 -1 -1335 -5 -1346 -10z"/>
                      <path d="M3650 4231 l0 -300 24 -28 24 -28 291 -3 291 -3 0 43 c0 40 -5 49
                      -58 101 l-58 57 -157 0 -157 0 0 161 0 160 -62 70 c-56 62 -66 69 -100 69
                      l-38 0 0 -299z"/>
                      <path d="M595 2828 c-11 -6 -28 -20 -37 -32 -16 -19 -18 -72 -18 -709 0 -751
                      -1 -732 57 -756 21 -8 410 -11 1521 -9 l1494 3 24 28 24 28 0 700 0 701 -29
                      29 -29 29 -1494 0 c-1066 -1 -1499 -4 -1513 -12z m2865 -748 l0 -560 -1360 0
                      -1360 0 0 560 0 560 1360 0 1360 0 0 -560z"/>
                      <path d="M1179 2441 c-24 -19 -24 -20 -27 -272 -2 -168 -7 -258 -14 -267 -9
                      -10 -27 -12 -74 -7 -58 7 -63 6 -77 -16 -9 -13 -19 -47 -22 -74 -9 -64 9 -82
                      97 -97 52 -9 73 -8 120 6 151 44 182 134 176 511 -3 193 -3 197 -27 216 -32
                      26 -120 26 -152 0z"/>
                      <path d="M1575 2448 c-76 -27 -128 -81 -150 -155 -19 -61 -13 -110 20 -169 28
                      -47 74 -81 167 -123 67 -30 93 -57 82 -85 -13 -35 -100 -37 -171 -5 -46 21
                      -46 21 -72 1 -34 -25 -58 -104 -42 -139 27 -60 201 -92 317 -58 144 41 213
                      199 145 333 -26 52 -50 70 -154 122 -71 35 -93 50 -95 67 -5 32 35 46 91 32
                      109 -28 125 -21 147 56 19 69 9 90 -55 114 -61 23 -177 27 -230 9z"/>
                      <path d="M2180 2451 c-94 -30 -173 -103 -217 -196 -26 -55 -28 -69 -28 -180 0
                      -116 1 -123 33 -187 40 -81 102 -139 181 -169 45 -17 72 -20 136 -17 95 5 155
                      29 217 87 148 136 154 435 11 578 -62 62 -123 87 -223 90 -47 1 -96 -1 -110
                      -6z m146 -187 c74 -35 110 -192 65 -290 -53 -116 -175 -121 -231 -9 -51 103
                      -20 257 60 297 43 22 63 22 106 2z"/>
                      <path d="M2669 2433 c-11 -13 -14 -82 -14 -355 0 -392 -7 -368 100 -368 93 0
                      95 4 95 191 l0 154 43 -68 c23 -38 72 -115 109 -173 l67 -104 69 0 c108 0 102
                      -22 102 373 0 389 6 367 -94 367 -93 0 -96 -5 -96 -179 0 -78 -4 -140 -8 -137
                      -5 3 -46 65 -92 138 -115 184 -108 178 -196 178 -54 0 -75 -4 -85 -17z"/>
                      </g>
                    </svg>
                    </a>
                    
                    <a download href={mediaUrl + 'csv/'+faqjsonlist.csv_filename}>
                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                      width="28px" height="28px" viewBox="0 0 512.000000 512.000000"
                      preserveAspectRatio="xMidYMid meet">

                      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                      fill="#000000" stroke="none">
                      <path d="M830 5104 c-42 -18 -86 -58 -108 -99 -15 -27 -17 -109 -20 -871 l-3
                      -842 -92 -4 c-112 -5 -179 -32 -235 -92 -81 -88 -77 -39 -77 -946 0 -801 0
                      -805 21 -851 54 -116 140 -169 291 -177 l92 -5 3 -536 c3 -588 1 -562 65 -623
                      65 -62 -61 -58 1793 -58 1854 0 1728 -4 1793 58 64 61 62 35 65 623 l3 536 92
                      5 c151 8 237 61 291 177 21 46 21 50 21 851 0 907 4 858 -77 946 -56 60 -123
                      87 -235 92 l-93 4 -1 302 c-1 165 -4 312 -8 326 -8 30 -994 1160 -1033 1184
                      -24 15 -138 16 -1270 16 -1088 -1 -1247 -3 -1278 -16z m2400 -714 l0 -530 29
                      -32 29 -33 466 -3 466 -3 0 -249 0 -250 -1660 0 -1660 0 0 815 0 815 1165 0
                      1165 0 0 -530z m-1620 -1310 c119 -15 220 -46 220 -68 0 -20 -56 -242 -63
                      -248 -2 -3 -40 5 -83 18 -111 31 -281 31 -374 -1 -200 -68 -300 -222 -301
                      -463 0 -254 107 -410 325 -473 98 -29 276 -20 412 20 20 6 22 -1 43 -107 12
                      -62 24 -121 27 -130 14 -49 -281 -96 -485 -77 -130 12 -200 31 -306 83 -108
                      54 -188 122 -250 216 -158 238 -167 604 -20 865 152 271 479 410 855 365z
                      m1120 -5 c100 -15 220 -51 220 -66 0 -4 -16 -67 -36 -138 l-37 -130 -50 20
                      c-113 45 -276 62 -362 38 -22 -6 -56 -28 -77 -49 -33 -32 -38 -44 -38 -83 0
                      -80 49 -119 248 -201 142 -58 184 -79 250 -128 171 -127 213 -366 98 -563 -94
                      -160 -343 -249 -625 -225 -159 14 -331 64 -331 96 0 8 14 71 31 140 26 103 35
                      125 48 120 9 -3 54 -19 101 -35 98 -34 243 -55 315 -45 131 17 201 97 175 195
                      -16 58 -80 108 -206 158 -304 122 -415 223 -443 402 -53 333 280 562 719 494z
                      m760 -27 c21 -47 239 -830 275 -983 20 -88 38 -162 40 -164 4 -4 16 38 49 181
                      28 119 97 363 209 738 l73 245 177 3 c138 2 177 0 177 -10 0 -7 -111 -345
                      -247 -750 l-248 -738 -200 0 -200 0 -238 743 c-130 408 -237 745 -237 750 0 4
                      81 7 180 7 178 0 181 0 190 -22z m730 -2308 l0 -480 -1660 0 -1660 0 0 480 0
                      480 1660 0 1660 0 0 -480z"/>
                      </g>
                      </svg>
                    </a>
                    <br />
                    
                  </span>
                  {"(" + faqjsonlist.faq_cate_name + ")"}
                </td>
      					<td>{js_date}</td>
      					<td>{faqjsonlist.completion_type}</td>
                <td>{(faqjsonlist.model_id ? faqjsonlist.model_id : '-')}</td>
      					<td>{sts_txt}</td>
                {/* <td className="actiongroup" style={{textAlign:"right"}} >
                     {btn_text}
                     {dlt_btn}
                </td> */}
                </tr>
              );
            }
          );
          return helperlistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="11" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }else {
      return (
        <tr>
          <td colspan="11" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
}
  
  multilist() {
    let catImg = '';
    let preview = '';
      var multilist = this.state.multilist;
      if (multilist != "undefined" && multilist != null) {
        console.log(multilist);
      
        if (multilist.length > 0) {
  
          if (Object.keys(multilist).length > 0) {
            const helperlistDetails2 = multilist.map(
              (multilist, Index) => {
                let sno = Index+1;
                
                return (
                  <tr key={multilist.id}>
                    <td><a download target="_blank" href={mediaUrl + 'faq_json/' + multilist.multi_file_name}>{(multilist.multi_file_name ? multilist.multi_file_name : '-')}</a></td>
                    <td>{(multilist.model_id ? multilist.model_id : '-')}</td>
                    <td><Link onClick={this.deleteai2.bind(this, multilist.multi_file_id)} className="" title="Delete">  <i className="fa fa-trash" aria-hidden="true"></i> </Link></td>
                  </tr>
                );
              }
            );
            return helperlistDetails2;
          }
        } else {
          return (
            <tr>
              <td colspan="11" className="v-align-nr">
                No Result
              </td>
            </tr>
          );
        }
      }else {
        return (
          <tr>
            <td colspan="11" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
  }

  render() {

    const {domainlist,universitynamelist} = this.state;
	  var current = this;
    var admin_id = localStorage.getItem('admin_id');
   let catImg = '';
  let preview = '';
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="Aifeed" /> 
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
   
         <div className="car-listing-wrap leaa-admin-head card">
            <div className="success_message"></div>
              <div className="listing-header">
                <div className="title" style={{width:"100%", textAlign:"left"}}>
                  <h3>Manage AI Feed</h3>
                  { this.state.faqjsonlist != '' &&
                  <button style={{ float:"right"}} onClick={this.teachai2}>Teach AI</button>
                  }

                  <a style={{ float:"right", margin:"15px"}} className="" href='/aifeed-import'>Import <i className="import-icon icon" aria-hidden="true"></i></a>
                </div>
               

                {/* <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div class="input_search">
                    <div className="search-inner search_input">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     
                    <div className="erroremail"></div>
                    </div>
                    <div className="search-inner search_select">
                    <Select name="category" options={lang.common.categories_option1} 
                          // onChange={this.handleInputChange}
                            onChange={this.handleChange}
                            />
                    </div>
                    <div class="search-inner search_btn">
                    <button className="" type="submit">Submit</button>

                    </div>
                    </div>
                 
                 </form>
                </div> */}
                <div className="add_button">  
                    
                </div>
                </div>      
                         
              <div style={{color:"green", fontSize: 30}} className="statusmessage"></div>
            
              <div className="car-listing-row table-avrech">
              <div className="overflow">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                  <th></th>
                  {/* <th width="10px">S.No</th> */}
			            <th>Upload Details</th>
			            <th>Uploaded Date</th>
			            <th>Type</th>
			            <th>Generated Model ID</th>
			            <th>Status</th>
                  {/* <th style={{width:"100px"}}>Action</th> */}
                   </tr>
                  </thead>
                  <tbody>{this.faqjsonlist()}</tbody>
                </Table>
			        	</div>
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>

              <div className="car-listing-row table-avrech">
              <div className="overflow">
                <Table striped bordered hover>
                  <thead>
                  <tr>
			            <th>Filename</th>
			            <th>Generated Model ID</th>
                  <th style={{width:"100px"}}>Action</th>
                  </tr>
                  </thead>
                  <tbody>{this.multilist()}</tbody>
                </Table>
			        </div>
            </div> 

            </div> 

            
        </div>  
      </div>
      
    </div>
    );
  }
}
export default List;