import React, { Component } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class Header extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
			admin_name : localStorage.getItem("admin_name"),
			admin_id : localStorage.getItem("admin_id"),
			admin_company_name : localStorage.getItem("admin_company_name"),
		};
		    this.logout  = this.logout.bind(this);

    }
    logout(){
    	localStorage.removeItem('admin_id');
    	localStorage.removeItem('admin_name');
    	localStorage.removeItem('loggedas');
    	localStorage.removeItem('admin_company_name');
    	//this.props.navigate('/logout');
		this.props.navigate('/');
      }
	
	componentDidMount() {
		if (localStorage.getItem("admin_id") === null) {
			this.props.navigate('/');
		}
    }
	
  render() {
    return (
	<div className="header">	
	  <div className="navbar-wrapper">
	  
			
			<div className="header-cnt">
			<div className="header-cnt-left">			
				<a href="javascript:void(0)" className="trigger-menu menu-icon">
                    <span className="icon_menu1"></span>
                    <span className="icon_menu2"></span>
                    <span className="icon_menu3"></span>
                </a>	
			</div>
			<div className="header-cnt-right">	
				<div className="myaccount">	
				<div className="myaccount-inner">
					<a href="javascript:void(0)" alt="user" className="user-name" >Welcome! {this.state.admin_name}</a>
					<a href="javascript:void(0)" alt="mail" className="dropdown-toggle user-icon r-icon user-main-head">
						<i className="ft-user"></i>
					</a>
				</div>	
					<ul className="user-menu">
						{localStorage.getItem('loggedas') === 'admin' && <li>
							<a className="user-username" href="/profile" title="Upload Docs">
								<i className="ft-user"></i> <span>Profile</span>
							</a>
						</li>}
						<li>
							<a className="user-username" href="/changepassword" title="Upload Docs">
								<i className="ft-lock"></i> <span>Change Password</span>
							</a>
						</li>
						<li>
						 <a className="user-username"
                                href="javascript:void(0);"
                                onClick={e => {
                                  e.preventDefault();
                                  this.logout();
                                }}
                              >
								<i className="ft-log-out"></i> <span>Logout</span>
							</a>
						</li>
					</ul>
				</div>	
			</div>		
			</div>			
			
		</div>
	</div>
	
    );
  }
}


export default withRouter(Header);