import React, { Component } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import { apiUrl} from'../Config/Config';
import axios from 'axios';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };


class Edit extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		const FontId = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;
		this.state = {
			fontname:'',
			fontfamilyname:'',
			fonturl:'',
			font_file: [],
			status:'',
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			statusmessage:'',
			c_font_id: FontId,
			Loading:false
		};
		

		axios.get(apiUrl+"font/listdetail?id="+this.state.c_font_id).then(res => {
			if(res.data.status === "success"){

				const formdata = res.data.fontlist;
				if(formdata.tf_status === 'A'){
					this.setState({selectedOption:{value: 'active', label: 'Active'}});
				}else{
					this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

				}
				this.setState({fontname:formdata.tf_name});							
				this.setState({fonturl: formdata.tf_import_url});
				this.setState({fontfamilyname: formdata.tf_font_family});
			}
		}); 

		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);
		//console.log(this.state.c_company_id+"-test");

    }
	

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }

	componentDidMount() {
		document.title = PageTitle('Font Edit');
    }

        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					c_font_id : formPayload.c_font_id,
					font_name    : formPayload.fontname,
					fonturl    : formPayload.fonturl,
					font_family_name    : formPayload.fontfamilyname,
					selectedFile : formPayload.selectedFile,
					status       : status,
				};
				axios.post(apiUrl+"font/edit",qs.stringify(postObject)).then(res => {
					
					if(res.data.status === "success"){
						this.setState({ Loading: false });
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');


								setTimeout(
								function() {
									$('.success_message').html('');
									//this.props.navigate('/fonts');
								}
								.bind(this),
								3000
								);
					} else {
						this.setState({ Loading: false });
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
							setTimeout(
							function() {
								$('.success_message').html('');
							}
							.bind(this),
							3000
							);
					}
				});
			}
	}

	validateForm() {

		const {fontname,fontfamilyname,selectedFile,fonturl} = this.state;
		let errors = {};
      	let formIsValid = true;
	
		if (!fontname) {
			errors++;
			$('.errorfont_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(fontname){
			$('.errorfont_name').html('');
		}
		
		if (!fontfamilyname) {
			errors++;
			$('.errorfontfamilyname').html('<span class="errorspan">Please fill the field</span>');
		}else if(fontname){
			$('.errorfontfamilyname').html('');
		}

		/*if (!fonturl) {
			errors++;
			$('.errorfonturl').html('<span class="errorspan">Please fill the field</span>');
		}else if(fontname){
			$('.errorfonturl').html('');
		}*/


		/*if (selectedFile === null || selectedFile === '') {
		$('.errorfile').html('<span class="errorspan">Please choose the font file</span>');
		errors++;
		}else if(selectedFile){
	    var filePath = selectedFile.name;
	    var allowedExtensions =  
                    /(\.ttf)$/i; 
        if(!allowedExtensions.exec(filePath)){
        	errors++;
		    $('.errorfile').html('<span class="errorspan">Please choose only ttf format</span>');
        }else{
            $('.errorfile').html('');
        } 
        }else{             
		$('.errorfile').html('');
		}*/

		if(errors>0) {
		
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }

  
  render() {
  	  const {selectedOption} = this.state;
	    const navigate = this.props.navigate;
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="fonts" />  

	
	<div className="content">	
		<div className="content-wrapper">
				  <div className="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
					<div className="content-wrapper">
					<a onClick={() => navigate(-1)}>Back</a>   
				</div>
			<div className="success_message"></div>
			<div className="title">
				<h4>Font Details</h4>
			</div>
			 <form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
		    	<div className="form-group">
					<label>Font Name<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="fontname" onChange={this.handleInputChange} className="form-control" value={this.state.fontname} autoComplete="off" />
					<div className="errorfont_name"></div>
				</div>
			
               <div className="form-group" style={{display:"none"}}>
					<label>Font</label>
				 <input type="file" onChange={this.onFileChange} multiple /> 
                <div className="errorfile"></div> 
                </div>
				
				
				<div className="form-group">
					<label>Font Import url</label>
					<textarea className="form-control" name="fonturl" onChange={this.handleInputChange}  value={this.state.fonturl} id="" rows="5"  autoComplete="off" />
					<div className="errorfonturl"></div>
				</div>
				
			
			</div>				
			<div className="form-left">
			<div className="form-group">
					<label>Exact Family Name<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="fontfamilyname" onChange={this.handleInputChange} className="form-control" value={this.state.fontfamilyname} autoComplete="off" />
					<div className="errorfontfamilyname"></div>
				</div>
				<div className="form-group">					
					<label>Status:</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
				</div>
				</div>	
			</div>	
			
						

		

			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default withRouter(Edit);