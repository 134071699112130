import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import Background from '../../common/images/side-bg.jpg';
import loginImg from "../../common/images/login-logo.png"
class MenuSidebar extends Component {
	
	constructor(props)
	{
		super(props);
		this.state = {
			  currentpage:this.props.currentpage,
			  admin_id:localStorage.getItem('admin_id')
		};
		
    }
	
	activeMenu(currentPage) {
        let status = '';
            if(this.state.currentpage===currentPage) {
                status = 'nav-item has-sub active'
            }else{
            status = 'nav-item has-sub enable';
        }
        return status;
    }
	
  render() {
    return (	
	<div className="sidebar-menu mobi-close">
	   <div className="main-menu menu-fixed menu-dark menu-accordion menu-shadow expanded" data-scroll-to-active="true" >
  <div className="navbar-header expanded">
    <ul className="nav navbar-nav flex-row position-relative">
      <li className="nav-item mr-auto">
        <a className="navbar-brand" href="#">
        {/* <img src={loginImg} /> */}
          <h3 className="brand-text">Admin</h3>
        </a>
      </li>
      <li className="nav-item d-md-none"><a href="javascript:void(0)" className="nav-link close-navbar mb-side-nav"><i className="ft-x"></i></a></li>
    </ul>
  </div>
  <div className="navigation-background" style={{background: `url(${Background})`}}></div>
  <div className="main-menu-content ps ps--active-y" style={{height: "587px;"}}>
  <Scrollbars className="custom-scroll-wrap">
    <ul className="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
      <li className={this.activeMenu('dashboard')}>
        <a href="/dashboard">
          <i className="ft-home"></i>
          <span className="menu-title" data-i18n="">Dashboard</span>
        </a>
      </li>
      {this.state.admin_id == 1 ?<li className={this.activeMenu('companylist')}>
		<a title="Company" href="/company">
			<i className="ft-company" aria-hidden="true"></i>
			<span>Companies</span>
		</a>
	</li>:''} 
      
   
          

     <li className={this.activeMenu('user')}>
        <a title="User" href="/user">
          <i className="ft-users"></i>
          <span className="menu-title" data-i18n="">Users</span>
        </a>
      </li>

      <li className={this.activeMenu('tabmanagement')}>
        <a title="User" href="/tabmanagement">
          <i className="ft-users"></i>
          <span className="menu-title" data-i18n="">Tab Management</span>
        </a>
      </li>
        <li className={this.activeMenu('dashboardmanagement')}>
					<a title="Dashboard Management Builder" href="/dashboardmanagement">
						<i className="ft-server"></i>
						<span>Dashboard Management</span>
					</a>
				</li>
        <li className={this.activeMenu('bookings')}>
					<a title="Dashboard Management Builder" href="/bookings">
						<i className="ft-server"></i>
						<span>Bookings</span>
					</a>
				</li>
        <li className={this.activeMenu('Aifeed')}>
					<a title='Manage AI Feed' href="/aifeed">
						<i className="ft-faq" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Manage AI Feed</span>
					</a>
				</li>
      <li className={this.activeMenu('fonts')}>
        <a href="/fonts">
          <i className="ft-type"></i>
          <span className="menu-title" data-i18n="">Fonts</span>
        </a>
      </li>
      {this.state.admin_id == 1 ?<li className={this.activeMenu('templates')}>
        <a href="/templates">
          <i className="ft-feather"></i>
          <span className="menu-title" data-i18n="">Templates</span>
        </a>
        </li>:''} 
	    <li className={this.activeMenu('setting')}>
        <a href="/setting">
          <i className="ft-settings"></i>
          <span className="menu-title" data-i18n="">Settings</span>
        </a>
      </li>
      
    </ul>
    </Scrollbars>
  </div>
</div>
	</div>		

	
    );
  }
}

export default MenuSidebar;